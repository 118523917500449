/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'school-cursive';
  src: url('assets/fonts/Deuxieme_Rang.otf');
}

@layer base {
  .debug {
    @apply border-red-500 border-8;
  }

  .font-cursive {
    font-family: 'school-cursive', cursive;
  }

  .nav-link {
    @apply text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md font-medium;
  }

  .nav-link.active {
    @apply bg-gray-900 text-white;
  }
}
